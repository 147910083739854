import React from "react";
import { Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import ComponentWow from "../../Wow";
import { MARKET } from "../../../constants";
import { langRedirect } from "../../../helpers/redirect";
import { usePathLang } from "../../../hooks/usePathLang";

const Lists = () => {
  const { t } = useTranslation();
  const { detectLang } = usePathLang();
  const redirectWithLang = (link: string) => langRedirect(detectLang, link)

  const renderLists = () => {
    return MARKET.map((val,index) => {
      const marketName = t(`dataMarkets.${val.linkDetail}.name`);
      const marketDesc = t(`dataMarkets.${val.linkDetail}.desc`);
    
      return (
        <div className="col-lg-4 col-md-4 col-sm-12 pb-1" key={`market-lists-${index}`}>
          <Link
            to={redirectWithLang(`/market/${val.linkDetail}`)}
            className="cat-item d-flex flex-column border mb-4"
            style={{ padding: "30px" }}
          >
            <div
              className="cat-img position-relative overflow-hidden mb-3"
            >
              <img
                className="img-fluid Sirv"
                data-src={val.srcSquare}
                alt={marketDesc}
              />
            </div>
            <h5 className="font-weight-semi-bold m-0">{marketName}</h5>
          </Link>
        </div>
      );
    });
  };

  return (
    <ComponentWow>
      <div className="container-fluid pt-5">
        <div className="row px-xl-5 pb-3">{renderLists()}</div>
      </div>
    </ComponentWow>
  );
};

export default Lists;
